import {SET_NEW_PASSWORD_URL} from 'src/constants/urls'
import axios from 'src/utils/axios';

export const doLogin = async (userName: string, password: string) => {
  const response = await axios.post('/v1_0_0/member/doLogin', {
    userName,
    password
  });
  return response.data;
};

export const getSelfInfo = async () => {
  const response = await axios.get('/v2_0_0/users/self');
  return response.data;
};


// PUT request to update user info
export const updateUserInfo = async (userInfo: any) => {
  const response = await axios.put('/v2_0_0/users/self', userInfo);
  return response;
}

// GET request to check token is valid or not
export const checkTokenRequest = async (token) => {
  const response = await axios.get(`${SET_NEW_PASSWORD_URL}/validateToken?token=${token}`);
  return response;
}

// PUT request to update password
export const updatePasswordRequest = async (token, password) => {
  const response = await axios.post(`${SET_NEW_PASSWORD_URL}?token=${token}`, {password});
  return response;
}
