import { Box } from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher';
import Chat from './Feedback';
import AdvertiserSwitcher from './AdvertiserSwitcher';
import { useContext, useEffect, useState } from 'react';
import { ImpactContext } from 'src/contexts/ImpactContext';
import { useSearchParams } from 'react-router-dom';
import DateSelection from './Dateselection';
import exportAsImage from 'src/utils/exportASImage';

function HeaderButtons() {
  const [searchParams] = useSearchParams();

  return (
    <Box sx={{}}>
      {/* <HeaderNotifications />
      <Chat /> */}

      {searchParams.get('advertiserId') !== null &&
        window.location.href.indexOf('insights') > -1 && <DateSelection />}
      {/* <LanguageSwitcher /> */}

      {searchParams.get('advertiserId') !== null &&
        window.location.href.indexOf('insights') > -1 && <AdvertiserSwitcher />}
    </Box>
  );
}

export default HeaderButtons;
