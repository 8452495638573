import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';


import BaseLayout from 'src/layouts/BaseLayout';

import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';


import dashboardsRoutes from './media';
import blocksRoutes from './site';
import applicationsRoutes from './setup';
import managementRoutes from './marketVisitation';
import accountRoutes from './account';
import baseRoutes from './base';
import mediaRoutes from './media';
import sitwRoutes from './site';
import setupRoutes from './setup';
import AdminRoutes from './admin';
import marketVisitationRoutes from './marketVisitation';
import CompanyAccountRoutes from './companyAccount';
import StatusComingSoon from 'src/content/pages/Status/ComingSoon';
import { Component, lazy, Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import useAuth from 'src/hooks/useAuth';
import { asyncLocalStorage } from 'src/utils/asyncLocalStorage';
import privateConsortiumRoute from './privateConsortium';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
  const Features = Loader(lazy(() => import('src/content/dashboards/Features')));

let isSuperAdmin = localStorage.getItem('isSuperAdmin');
const routeradmin: RouteObject[] = [
  {
    path: '',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <BaseLayout />,
        children: AdminRoutes
      }
    ]
  },
  {
    path: 'features',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: baseRoutes
  },
  {
    path: 'account',
    children: accountRoutes
  },

  {
    path: 'company-account',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: CompanyAccountRoutes
  },
    // Conditionally render the 'private-consortium' route
    ...(process.env.REACT_APP_ENV !== 'prod' ? [{
      path: 'private-consortium',
      element: (
        <Authenticated>
          <ExtendedSidebarLayout />
        </Authenticated>
      ),
      children: privateConsortiumRoute
    }] : []),

  // Bottom Navigation Layout

  {
    path: 'admin',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: AdminRoutes
  },

  // Extended Sidebar Layout

  {
    path: 'insights',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="media" replace />
      },
      {
        path: 'media',
        children: mediaRoutes
      },
      {
        path: 'site',
        children: sitwRoutes
      },
      {
        path: 'setup',
        children: setupRoutes
      },
      {
        path: 'market-visitation',
        children: marketVisitationRoutes
      }
    ]
  }
];

export default routeradmin;
