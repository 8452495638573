export const LOGIN_URL = '/v1_0_0/member/doLogin';
export const SET_NEW_PASSWORD_URL = '/v1_0_0/member/resetPassword';
export const SELF_INGO_URL = '/v2_0_0/users/self';
export const CUSTOM_DATE_RANGE_URL = '/v2_0_0/impact/advertisers';
export const TOP_LINE_STATS_URL = '/v2_0_0/media_impact/advertisers';
export const TOP_HOTEL_ORIGIN_URL = '/v2_0_0/impact/advertisers';
export const TOP_FLIGHT_ORIGIN_URL = '/v2_0_0/impact/advertisers';
export const KEY_PROFILE_INSIGHTS_URL = '/v2_0_0/media_impact/advertisers';
export const TRIP_PLANNING_WINDOW_URL = '/v2_0_0/impact/advertisers';
export const MARKETIN_ACTIVITY_URL = '/v2_0_0/media_impact/advertisers';
export const SITE_PIXELS_URL = '/v2_0_0/site_pixels?accountId=';
export const CUSTOM_DATE_RANGE_LIST_URL = '/v2_0_0/impact/advertisers';
export const DELETE_CUSTOM_DATE_RANGE_URL = '/v2_0_0/impact/custom_date_ranges';

// setup
export const BULK_UPLOAD_PIXELS_URL = '/v2_0_0/pixels?accountId=';

export const CREATE_WEBSITE_PIXEL_URL = '/v2_0_0/pixels?accountId=';
export const EDIT_WEBSITE_PIXEL_URL = '/v2_0_0/pixels?accountId=';
export const DELETE_WEBSITE_PIXEL_URL = '/v2_0_0/pixels';

export const MEDIA_PIXELS_LIST_URL = '/v2_0_0/media_pixels?accountId=';
export const CREATE_MEDIA_PIXELS_URL = '/v2_0_0/pixels?accountId=';
export const EDIT_MEDIA_PIXELS_URL = '/v2_0_0/pixels?accountId=';
export const DELETE_MEDIA_PIXELS_URL = '/v2_0_0/pixels';

export const CREATE_CLEEN_ROOM_URL = '/v2_0_0/pixels?accountId=';
export const EDIT_CLEEN_ROOM_URL = '/v2_0_0/pixels?accountId=';
export const DELETE_CLEEN_ROOM_URL = '/v2_0_0/pixels';
export const CLEEN_ROOM_LIST_URL = '/v2_0_0/clean_room_pixels?accountId=';

export const PARTNER_LIST_URL = '/v2_0_0/partner?advertiserId=';
export const CLICK_PIXELS_URL = '/v2_0_0/click_pixels?accountId=';
export const CREATE_CLICK_PIXELS_URL = '/v2_0_0/pixels?accountId=';
export const CLICK_TRACKER_PLATFORM_URL = '/v2_0_0/click_tracker_platform';
export const DELETE_CLICK_PIXELS_URL = '/v2_0_0/pixels';
export const EDIT_CLICK_PIXELS_URL = '/v2_0_0/pixels?accountId=';
export const THRESHOLD_CHECK_URL = '/v2_0_0/impact/advertisers';
export const AGE_DISTRIBUTION_URL = '/v2_0_0/impact/advertisers';
export const HOUSEHOLD_INCOME_URL = '/v2_0_0/impact/advertisers';
export const SITE_CATEGORY_DISTRIBUTION_URL = '/v2_0_0/impact/advertisers';
export const CLASS_DISTRIBUTION_URL = '/v2_0_0/impact/advertisers';
export const LOYAITY_DISTRIBUTION_URL = '/v2_0_0/impact/advertisers';
export const LOYALITY_TIER_DISTRIBUTION_URL = '/v2_0_0/impact/advertisers';
export const BUSINESS_LEISURE_URL = '/v2_0_0/impact/advertisers';
export const MEDIA_PIXEL_FILTER_URL = '/v2_0_0/media_pixels/accountId=';
export const CREATE_PARTNER_URL = '/v2_0_0/partner?accountId=';
export const EDIT_PARTNER_URL = '/v2_0_0/partner?accountId=';
export const DELETE_PARTNER_URL = '/v2_0_0/partner';
export const FEATURE_LIST_URL = '/v2_0_0/avrtzr';
export const ADVERTISER_LIST_URL = '/v2_0_0/avrtzr?accountId=';
export const  RESET_PASSWORD_URL = '/v1_0_0/member/resetPasswordRequest';

//Activity Breakdown
export const ACTIVITY_SUMMARY_URL = '/v2_0_0/impact_overview/activity_summary?advertiserId=';
export const TRAVEL_TYPE_SUMMARY_URL = '/v2_0_0/impact_overview/travel_type_summary?advertiserId=';
export const HOTEL_TOtalS_URL = '/v2_0_0/impact_overview/hotel_totals?advertiserId=';
export const HOTEL_BUSINESS_TRAVEL_URL = '/v2_0_0/impact_overview/hotel_business_travel?advertiserId=';
export const HOTEL_LEISURE_TRAVEL_URL = '/v2_0_0/impact_overview/hotel_leisure_travel?advertiserId=';
export const FLIGHT_TOtalS_URL = '/v2_0_0/impact_overview/flight_totals?advertiserId=';
export const FLIGHT_BUSINESS_TRAVEL_URL = '/v2_0_0/impact_overview/flight_business_travel?advertiserId=';
export const FLIGHT_LEISURE_TRAVEL_URL = '/v2_0_0/impact_overview/flight_leisure_travel?advertiserId=';


// Imapct markets URLs

export const TOP_HOTEL_ORIGIN_MARKETS_URL = '/v2_0_0/impact/advertisers';
export const TOP_HOTEL_DESTINATION_URL = '/v2_0_0/impact/advertisers';
export const TOP_ALTERNATE_SEARCHED_HOTEL_DESTINATION_URL = '/v2_0_0/impact/advertisers';
export const TOP_ALTERNATE_BOOKED_HOTEL_DESTINATION_URL = '/v2_0_0/impact/advertisers';
export const TOP_FLIGHT_ORIGIN_MARKETS_URL = '/v2_0_0/impact/advertisers';
export const TOP_FLIGHT_DESTINATION_URL = '/v2_0_0/impact/advertisers';
export const TOP_ALTERNATE_SEARCHED_FLIGHT_DESTINATION_URL = '/v2_0_0/impact/advertisers';
export const TOP_ALTERNATE_BOOKED_FLIGHT_DESTINATION_URL = '/v2_0_0/impact/advertisers';


// Origin breakdown URLs

export const ORIGIN_MARKET_BREAKDOWN_URL = '/v2_0_0/impact_overview/origin_market_breakdown?advertiserId='; 
export const ORIGIN_MARKET_BREAKDOWN_DMA_URL = '/v2_0_0/impact_overview/origin_market_breakdown_dma?advertiserId='
// Trip Planning URLs

export const TRIP_PLANNING_URL = '/v2_0_0/impact/advertisers';
export const flight_booking_attribution_distribution_url = '/v2_0_0/impact/advertisers';
export const hotel_booking_attribution_distribution_url = '/v2_0_0/impact/advertisers';
// Users management URLs

export const USERS_LIST_URL = '/v2_0_0/users?accountId=';
export const ADVERTISER_TREE_URL = '/v2_0_0/avrtzr/tree?accountId=';
export const ROLE_LIST_URL = '/v2_0_0/roles?accountId=';
export const USER_URL = '/v2_0_0/users';

// Roole management URLs
export const ROLE_URL = '/v2_0_0/roles';


// Advertiser management URLs

export const ADVERTISERS_LIST_URL = '/v2_0_0/avrtzr?accountId=';
export const ACCOUNTS_URL = '/v2_0_0/accounts';
export const CATEGORIES_URL = '/v2_0_0/categories';
export const CURRENCIES_URL = '/v2_0_0/currencies';
export const TIMEZONES_URL = '/v2_0_0/timezones';
export const THIRD_PARTY_REPORTING_SOURCES_URL = '/v2_0_0/third_party_reporting_sources';
export const ADVERTISER_GROUP_URL = '/v2_0_0/avrtzr?accountId=';
export const ADVERTISER_URL = '/v2_0_0/avrtzr';


// Billing Management URLs
export const BILLING_INF0_URL = '/v2_0_0/accounts';
export const COUNTRY_LIST_URL = '/v2_0_0/countries';
export const EDIT_BILLING_INFO_URL = '/v2_0_0/accounts/billinginfo?accountId=';

// Overlap & Unique URLs

export const OVERLAP_UNIQUE_URL = '/v2_0_0/impact_overview/overlap_uniques?advertiserId=';

//Media Referral URLs

export const SITE_MERTICS_URL = '/v2_0_0/site_metrics/advertisers';

// Last Refresh Time URL
export const MEDIA_LAST_REFRESH_TIME_URL = '/v2_0_0/last_fresh/impact_overview';
export const SITE_LAST_REFRESH_TIME_URL = '/v2_0_0/last_fresh/impact_insights';
export const PIXELS_LAST_REFRESH_TIME_URL = '/v2_0_0/last_fresh/impact_pixel';

//Pixel Mapping
export const PIXEL__PARTNER_MAPPING_URL = '/v2_0_0/pixel_partner_mapping?accountId=';

// SubFeatures URL
export const SUB_FEATURES_URL = '/v2_0_0/avrtzr'