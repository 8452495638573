import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  TooltipProps,
  alpha,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { KeyboardReturn } from '@mui/icons-material';
import { useContext } from 'react';
import { ImpactContext } from 'src/contexts/ImpactContext';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function CollapsibleSidebarFooter() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { isSuperAdmin, setIsSuperAdmin, viewMode } = useContext(ImpactContext);

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        height: 100
      }}
      display="flex"
      
      alignItems="center"
      justifyContent="center"
    >
     
      <LightTooltip placement="top" arrow title={t('Logout')}>
        <IconButton
          sx={{
            background: `${theme.sidebar.menuItemIconColor}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
               background: `${alpha(theme.colors.alpha.black[100], 0.8)}`,
              // color: `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          onClick={handleLogout}
        >
          <PowerSettingsNewTwoToneIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
      {isSuperAdmin === 'false' && viewMode === 'admin' && (
        <LightTooltip
          placement="top"
          arrow
          title={t('Back to Account Overview')}
        >
          <IconButton
            sx={{
              ml: 2,
              background: `${theme.sidebar.menuItemIconColor}`,
              color: `${theme.colors.alpha.trueWhite[70]}`,
              transition: `${theme.transitions.create(['all'])}`,

              '&:hover': {
                background: `${alpha(theme.colors.alpha.black[100], 0.8)}`,
                // color: `${theme.colors.alpha.trueWhite[100]}`
              }
            }}
            onClick={() => {
              setIsSuperAdmin('true');
              localStorage.setItem('isSuperAdmin', 'true');
              navigate(`/admin/accounts`, {
                replace: true
              });
            }}
          >
            <KeyboardReturn fontSize="small" />
          </IconButton>
        </LightTooltip>
      )}
    </Box>
  );
}

export default CollapsibleSidebarFooter;
